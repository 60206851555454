<template>
  <div class="notificationLiveCard">
    <div class="notificationLiveHead">
      <div class="notificationLiveUser">
        <UserAvatar class="notificationLiveAvatar" :user="$adminUser" :type="`post`" size="60px" />
        <div class="notificationLiveUserInfo">
          <div class="notificationLiveUserFullName">{{ $adminUser.name }}</div>
          <div class="notificationLiveUserName">@{{ $adminUser.user }}</div>
        </div>
      </div>
    </div>
    <div class="notificationLiveBody">
      <div class="notificationLiveMessage" v-html="notification.message"></div>
    </div>
    <div class="notificationLiveOptions">
      <Confirm :confirm="confirm" @cancel="closeAll" @accept="closeThis" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["notification", "fromUser"],
  methods: {
    closeThis: function() {
      this.$store.commit("closeThisLiveNotification", this.notification._id);
    },
    closeAll: function() {
      this.$store.commit("closeAllLivesNotifications");
    },
  },
  computed: {
    confirm: function() {
      const confirm = {
        cancel: {
          label: this.$locale["close_all"],
          icon: "times",
        },
        accept: {
          label: this.$locale["close"],
          icon: "check",
        },
      };

      const numNtf = this.$store.state.notifications.lives.length;

      if (numNtf < 2) {
        confirm.cancel = undefined;
      }

      if (numNtf > 1) {
        confirm.cancel.label = `${this.$locale["close_all"]} (${numNtf})`;
      }

      return confirm;
    },
  },
};
</script>

<style lang="scss">
.notificationLiveCard {
  position: fixed;
  bottom: $mpadding;
  left: $mpadding;
  background-color: #fff;
  padding: $mpadding;
  border-radius: $mpadding;
  width: calc(100% - #{$mpadding * 2});
  @include dshadow;
  .notificationLiveUser {
    display: flex;
    gap: $mpadding/2;
    align-items: center;
  }
  .notificationLiveUserFullName {
    font-size: 120%;
    font-weight: bold;
  }
  .notificationLiveMessage {
    padding: $mpadding/2 0 0 0;
    display: flex;
    gap: $mpadding;
  }
  .notificationLiveOptions {
    padding: $mpadding 0 0 0;
  }
  .notificationLiveOptions .confirm-content {
    justify-content: flex-end;
  }
  .notificationLiveProductImage {
    display: inline-block;
    width: $mpadding * 3;
    height: $mpadding * 3;
    background-image: var(--img);
    background-size: cover;
    background-position: center;
    border-radius: $mpadding/2;
  }
  @include screen($desktop_width) {
    bottom: $mpadding * 2;
    left: $mpadding * 2;
    max-width: 380px;
  }
}
</style>
